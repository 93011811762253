<template>
  <div>
    <cbs-objects-tbl meta-filter="procedure" title="PROCEDURES" />
  </div>
</template>

<script>
import CbsObjectsTbl from '@/cubus/components/objects/CbsObjectsTbl.vue'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsObjectsProcedures',
  components: {
    CbsObjectsTbl,
  },
  created() {
    useCubus.setPageTitle('Procedures')
  },
}
</script>

<style scoped>

</style>
